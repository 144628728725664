jQuery(function (s) {
    if ("undefined" == typeof wc_single_product_params) return !1;
    s("body")
        .on("init", ".wc-tabs-wrapper, .woocommerce-tabs", function () {
            s(".wc-tab, .woocommerce-tabs .panel:not(.panel .panel)").hide();
            var t = window.location.hash,
                e = window.location.href,
                i = s(this).find(".wc-tabs, ul.tabs").first();
            0 <= t.toLowerCase().indexOf("comment-") || "#reviews" === t || "#tab-reviews" === t
                ? i.find("li.reviews_tab a").click()
                : 0 < e.indexOf("comment-page-") || 0 < e.indexOf("cpage=")
                ? i.find("li.reviews_tab a").click()
                : "#tab-additional_information" === t
                ? i.find("li.additional_information_tab a").click()
                : i.find("li:first a").click();
        })
        .on("click", ".wc-tabs li a, ul.tabs li a", function (t) {
            t.preventDefault();
            var e = s(this),
                i = e.closest(".wc-tabs-wrapper, .woocommerce-tabs");
            i.find(".wc-tabs, ul.tabs").find("li").removeClass("active"), i.find(".wc-tab, .panel:not(.panel .panel)").hide(), e.closest("li").addClass("active"), i.find(e.attr("href")).show();
        })
        .on("click", "a.woocommerce-review-link", function () {
            return s(".reviews_tab a").click(), !0;
        })
        .on("init", "#rating", function () {
            s("#rating")
                .hide()
                .before(
                    '<p class="stars">\t\t\t\t\t\t<span>\t\t\t\t\t\t\t<a class="star-1" href="#">1</a>\t\t\t\t\t\t\t<a class="star-2" href="#">2</a>\t\t\t\t\t\t\t<a class="star-3" href="#">3</a>\t\t\t\t\t\t\t<a class="star-4" href="#">4</a>\t\t\t\t\t\t\t<a class="star-5" href="#">5</a>\t\t\t\t\t\t</span>\t\t\t\t\t</p>'
                );
        })
        .on("click", "#respond p.stars a", function () {
            var t = s(this),
                e = s(this).closest("#respond").find("#rating"),
                i = s(this).closest(".stars");
            return e.val(t.text()), t.siblings("a").removeClass("active"), t.addClass("active"), i.addClass("selected"), !1;
        })
        .on("click", "#respond #submit", function () {
            var t = s(this).closest("#respond").find("#rating"),
                e = t.val();
            if (0 < t.length && !e && "yes" === wc_single_product_params.review_rating_required) return window.alert(wc_single_product_params.i18n_required_rating_text), !1;
        }),
        s(".wc-tabs-wrapper, .woocommerce-tabs, #rating").trigger("init");
});


var c = document.body.className;
c = c.replace(/woocommerce-no-js/, 'woocommerce-js');
document.body.className = c;

var wc_single_product_params =
{"i18n_required_rating_text":"Proszę wybrać ocenę",
"review_rating_required":"yes",
"flexslider":
{"rtl":false,
"animation":"slide",
"smoothHeight":true,
"directionNav":false,
"controlNav":"thumbnails",
"slideshow":false,
"animationSpeed":500,
"animationLoop":false,
"allowOneSlide":false},
"zoom_enabled":"1",
"zoom_options":[],
"photoswipe_enabled":"1",
"photoswipe_options":
{"shareEl":false,
"closeOnScroll":false,
"history":false,
"hideAnimationDuration":0,
"showAnimationDuration":0},
"flexslider_enabled":"1"};

