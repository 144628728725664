function setCookie(cname,cvalue,exdays) {
   var d = new Date();
   d.setTime(d.getTime() + (exdays*24*60*60*1000));
   var expires = "expires=" + d.toGMTString();
   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
   console.log('cookie is set')
}

function getCookie(cname) {
 const decodedCookie = document.cookie
 const ca = decodedCookie.split(';').filter(el => el.includes(cname));
   if(ca.length == 0){
      return false;
   }
 const cvalue = ca[0].split('=')[1];
 return cvalue;
}

if(getCookie('cookiesmsg') == 'hide')
  {
    $('.cookiesmsg').addClass('d-none');
  }
$('#cookiesmsgremove').click(function(e){
  e.preventDefault();
  $(this).parent().addClass('d-none');
  setCookie('cookiesmsg', 'hide', 30)
})