import $ from 'jquery';
window.$ = window.jQuery = $;

////additional scripts with jQuery
require('../../public/frontend/js/owl.carousel.min.js');
require('../../public/frontend/js/custom.js');
require('../../public/frontend/js/single-product.min.js');
require('../../public/frontend/fotorama/fotorama.js');
require('./front-end-scripts/cookie-script-jquery.js')
//require('../../public/frontend/js/jquery.lazy.min.js');
//require('../../public/frontend/js/lazyfunc.js');